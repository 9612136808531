import { ArrowForward } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Box, alpha, useTheme } from '@mui/system';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { buttonStateChipColorMap } from 'src/@types/button';
import Chip, { ChipColorType } from 'src/components/core/atoms/Chip';
import Link from 'src/components/core/atoms/Link';
import { Logo } from 'src/components/core/atoms/Logo';
import { ROUTES } from 'src/config';
import useDevice from 'src/hooks/useDevice';
import { FOOTER_SECTIONS } from 'src/navigation';
import { getListItemKey } from 'src/utils/format';
import snackbar from 'src/utils/snackbar';

// ----------------------------------------------------------------------

const FOOTER_FOOTNOTES = [
  { name: 'Terms of Service', path: ROUTES.TOS },
  { name: 'Privacy Policy', path: ROUTES.PRIVACY_POLICY },
  { name: 'Compliance', path: ROUTES.COMPLIANCE }
];

function SubscribeBox() {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Grid item sx={{ width: '100%' }}>
        <Grid
          container
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderColor: 'border.main'
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              width: '100%',
              mb: 1
            }}
          >
            <TextField
              disabled={isLoading}
              fullWidth
              autoComplete="off"
              placeholder="name@example.com"
              color="info"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              InputProps={{
                sx: {
                  borderRadius: 4,
                  px: 2
                },
                endAdornment: (
                  <IconButton
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                      borderRadius: 3,
                      backgroundColor: alpha(
                        theme.palette.primary.main,
                        0.04
                      ),
                      minHeight: '40px',
                      minWidth: '40px',
                      maxHeight: '40px',
                      maxWidth: '40px',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        backgroundColor: alpha(
                          theme.palette.primary.main,
                          0.08
                        )
                      }
                    }}
                    onClick={() => {
                      const emailRegex = new RegExp(
                        // eslint-disable-next-line no-control-regex
                        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
                      );

                      if (!emailRegex.test(email)) {
                        snackbar.error(
                          'Please enter a valid email address.'
                        );
                        return;
                      }

                      const apiUrl =
                        'https://api.airtable.com/v0/appAcsAUHyjZm3saP/Email';
                      const apiToken =
                        'patXWunKdmPHVzRrU.3be4727f6d74079742555d08e244190929bfd2efd91708d654f8a4a5db4fa0c8';

                      const headers = {
                        Authorization: `Bearer ${apiToken}`,
                        'Content-Type': 'application/json'
                      };

                      const data = {
                        records: [
                          {
                            fields: {
                              Name: '',
                              Email: email,
                              'Product Roadmap': 'Subscribed'
                            }
                          }
                        ]
                      };

                      setIsLoading(true);
                      fetch(apiUrl, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(data)
                      })
                        .then(() => {
                          setIsLoading(false);
                        })
                        .then(() => {
                          snackbar.success(
                            'Subscribed! See you in the inbox.'
                          );
                        })
                        .catch(() => {
                          snackbar.error(
                            'Unable to subscribe to newsletter.'
                          );
                        });
                      /* TODO: subscribe */
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '40px',
                        width: '40px'
                      }}
                    >
                      <ArrowForward
                        sx={{
                          color: 'primary.main',
                          rotate: isHovering ? '-30deg' : '0deg',
                          transformOrigin: 'center',
                          transition: 'all 0.2s ease-in-out'
                        }}
                      />
                    </Box>
                  </IconButton>
                )
              }}
            />
          </Grid>

          <Grid
            item
            sx={{
              pl: 3.5,
              mr: 1,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              variant="caption"
              display="inline"
              sx={{ color: 'text.primaryAlt2' }}
            >
              By subscribing, you agree to our{' '}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="body2"
                display="inline"
                color="text.primaryAlt2"
              >
                <Link
                  variant="caption"
                  path={ROUTES.PRIVACY_POLICY}
                  color="text.primaryAlt2"
                >
                  Privacy&nbsp;Policy
                </Link>
              </Typography>
              <Typography
                variant="body2"
                display="inline"
                color="text.primaryAlt2"
              >
                .
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default function Footer() {
  const { isOnlyDesktop, isOnlyTablet, isOnlyMobile } = useDevice();
  const router = useRouter();

  return (
    <Grid
      container
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        p: 2,
        position: 'relative'
      }}
    >
      {isOnlyMobile && (
        <>
          <Grid
            item
            onClick={() => router.push(ROUTES.ROOT)}
            sx={{ pb: 3 }}
          >
            <Logo />
          </Grid>
          <Grid item sx={{ pt: 4, width: '100%' }}>
            <Grid container>
              {FOOTER_SECTIONS.map((item, itemId) => (
                <Grid
                  item
                  sx={{ width: '100%', mb: 4 }}
                  key={getListItemKey(itemId)}
                >
                  <Grid
                    container
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="overline">
                        {item.name}
                      </Typography>
                    </Grid>
                    {item.items.map((subitem) => (
                      <Grid
                        item
                        key={getListItemKey(subitem.name)}
                        sx={{
                          mt: 1,
                          flexWrap: 'row',
                          position: 'relative'
                        }}
                      >
                        {subitem.comingSoon ? (
                          <div className="flex flex-nowrap items-center">
                            <p className="text-muted">
                              {subitem.name}
                            </p>
                            <p className="ml-sm text-primary">Soon</p>
                          </div>
                        ) : (
                          <Link
                            color="text.primaryAlt2"
                            path={subitem.path}
                          >
                            {subitem.name}
                          </Link>
                        )}
                        {subitem?.state && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 1,
                              right: -200,
                              height: '100%',
                              width: '200px',
                              // border: 1,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Chip
                              label={subitem.state}
                              size="mini"
                              color={
                                buttonStateChipColorMap[
                                  subitem.state
                                ] as ChipColorType
                              }
                              sx={{ ml: 1 }}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
              {/* <Grid item sx={{ width: '50%' }} /> */}
              <Grid item sx={{ width: '100%' }}>
                <Grid item sx={{ mb: 2 }}>
                  <Typography variant="overline">
                    Newsletter
                  </Typography>
                </Grid>
                <Grid item>
                  <SubscribeBox />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              height: '1px',
              width: '100%',
              backgroundColor: 'info.dark',
              mt: 5
            }}
          />
          <Grid item sx={{ width: '100%', pb: 5, mt: 3 }}>
            <Grid
              container
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'baseline'
              }}
            >
              <Grid item>
                <Grid container sx={{ flexDirection: 'column' }}>
                  {FOOTER_FOOTNOTES.map((item) => (
                    <Grid
                      item
                      key={getListItemKey(item.name)}
                      sx={{ mt: 0 }}
                    >
                      <Link path={item.path}>
                        <Typography variant="body2" display="inline">
                          {item.name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item sx={{ mt: 2 }}>
                <Grid container sx={{ flexDirection: 'row' }}>
                  <Typography variant="body2" display="inline">
                    ©{new Date().getFullYear()} EmitIQ, All rights
                    reserved.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {isOnlyTablet && (
        <>
          <Grid
            item
            onClick={() => router.push(ROUTES.ROOT)}
            sx={{ py: 3 }}
          >
            <Logo />
          </Grid>
          <Grid item sx={{ pt: 4, width: '100%' }}>
            <Grid container>
              {FOOTER_SECTIONS.map((item, itemId) => (
                <Grid
                  item
                  sx={{ width: '50%', mb: 4 }}
                  key={getListItemKey(itemId)}
                >
                  <Grid
                    container
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="overline">
                        {item.name}
                      </Typography>
                    </Grid>
                    {item.items.map((subitem) => {
                      return (
                        <Grid
                          item
                          key={getListItemKey(subitem.name)}
                          sx={{
                            mt: 1,
                            flexWrap: 'row',
                            position: 'relative'
                          }}
                        >
                          {subitem.comingSoon ? (
                            <div className="flex flex-nowrap items-center">
                              <p className="text-muted">
                                {subitem.name}
                              </p>
                              <p className="ml-sm text-primary">
                                Soon
                              </p>
                            </div>
                          ) : (
                            <Link
                              color="text.primaryAlt2"
                              path={subitem.path}
                            >
                              {subitem.name}
                            </Link>
                          )}
                          {subitem?.state && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 1,
                                right: -200,
                                height: '100%',
                                width: '200px',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Chip
                                label={subitem.state}
                                size="mini"
                                color={
                                  buttonStateChipColorMap[
                                    subitem.state
                                  ] as ChipColorType
                                }
                                sx={{ ml: 1 }}
                              />
                            </Box>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              ))}
              {/* <Grid item sx={{ width: '50%' }} /> */}
              <Grid item sx={{ width: '50%' }}>
                <Grid item sx={{ mb: 2 }}>
                  <Typography variant="overline">
                    Newsletter
                  </Typography>
                </Grid>
                <Grid item>
                  <SubscribeBox />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              height: '1px',
              width: '100%',
              backgroundColor: 'info.dark',
              my: 3
            }}
          />
          <Grid item sx={{ width: '100%', pb: 5 }}>
            <Grid
              container
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline'
              }}
            >
              <Grid item>
                <Grid container sx={{ flexDirection: 'row' }}>
                  {FOOTER_FOOTNOTES.map((item) => (
                    <Grid
                      item
                      key={getListItemKey(item.name)}
                      sx={{ mr: 3 }}
                    >
                      <Link path={item.path}>
                        <Typography variant="body2" display="inline">
                          {item.name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item sx={{ mt: 2 }}>
                <Grid container sx={{ flexDirection: 'row' }}>
                  <Typography variant="body2" display="inline">
                    ©{new Date().getFullYear()} EmitIQ, All rights
                    reserved.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {isOnlyDesktop && (
        <>
          <Grid
            item
            onClick={() => router.push(ROUTES.ROOT)}
            sx={{ py: 3 }}
          >
            {/* <MonochromeLogo /> */}
            <Logo />
          </Grid>
          <Grid item sx={{ pt: 4, width: '100%' }}>
            <Grid
              container
              sx={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%'
              }}
            >
              {FOOTER_SECTIONS.map((item, itemId) => (
                <Grid
                  item
                  sx={{ width: '25%', mb: 4 }}
                  key={getListItemKey(itemId)}
                >
                  <Grid
                    container
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="overline">
                        {item.name}
                      </Typography>
                    </Grid>
                    {item.items.map((subitem) => (
                      <Grid
                        item
                        key={getListItemKey(subitem.name)}
                        sx={{
                          mt: 1,
                          flexWrap: 'row',
                          position: 'relative'
                        }}
                      >
                        {subitem.comingSoon ? (
                          <div className="flex flex-nowrap items-center">
                            <p className="text-muted">
                              {subitem.name}
                            </p>
                            <p className="ml-sm text-primary">Soon</p>
                          </div>
                        ) : (
                          <Link
                            color="text.primaryAlt2"
                            path={subitem.path}
                          >
                            {subitem.name}
                          </Link>
                        )}
                        {subitem?.state && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 1,
                              right: -200,
                              height: '100%',
                              width: '200px',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Chip
                              label={subitem.state}
                              size="mini"
                              color={
                                buttonStateChipColorMap[
                                  subitem.state
                                ] as ChipColorType
                              }
                              sx={{ ml: 1 }}
                            />
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
              <Grid item sx={{ width: '35%' }}>
                <Grid item sx={{ mb: 2 }}>
                  <Typography variant="overline">
                    Newsletter
                  </Typography>
                </Grid>
                <Grid item sx={{ pr: 2 }}>
                  <SubscribeBox />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              height: '1px',
              width: '100%',
              backgroundColor: 'info.dark',
              mt: 4,
              mb: 4
            }}
          />
          <Grid item sx={{ width: '100%', pb: 3 }}>
            <Grid
              container
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                pb: 1
              }}
            >
              <Grid item>
                <Grid container sx={{ flexDirection: 'row' }}>
                  {FOOTER_FOOTNOTES.map((item) => (
                    <Grid
                      item
                      key={getListItemKey(item.name)}
                      sx={{ mr: 3 }}
                    >
                      <Link path={item.path}>
                        <Typography variant="body2" display="inline">
                          {item.name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container sx={{ flexDirection: 'row' }}>
                  <Typography variant="body2" display="inline">
                    ©{new Date().getFullYear()} EmitIQ, All rights
                    reserved.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
